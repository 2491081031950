<template>
  <div class="">

    <form @submit.prevent="submitReservation">

      <div class="form-group">
        <div class="row d-flex overflow-x-auto flex-nowrap ">
          <div v-for="date in availableDates" :key="date.value" class="col p-2">
            <button type="button" class="w-100"
              :class="['btn', 'btn-outline-primary', { active: date.value === reservation.date }]"
              @click="selectDate(date.value)">
              <!-- {{ date.label }} -->
              <span v-html="date.label"> </span>
            </button>
          </div>
        </div>
      </div>
      <br>
      <div v-if="subscription.soldHoursRest" class="p-2 shadow rounded alert alert-info">
        <b class="fw-bold"> {{ subscription.fieldFullName }} </b> dispose d'un abonnement de <b class="fw-bold"> {{
          subscription.hours }} </b> heures.
        Cela signifie qu'il y a <b class="fw-bold"> {{ subscription.soldHoursRest }} </b> heures de crédit disponibles.
        Pour utiliser ce crédit sur cette réservation, cliquez sur « Réserver avec mon groupe ».
      </div>

      <div>
        <div class="d-flex align-items-center">
          <input type="checkbox" name="" id="subscription_confirmation_button" v-model="reservation.is_subscription" />
          <label for="subscription_confirmation_button" class="ms-2 mt-1 btn btn-link nav-link  fs-6">
            Réserver avec mon pack
          </label>
        </div>
      </div>
      <!-- {{ subscription }} -->
      <br>

      <div v-if="timeSlots.length > 0" class="form-group">
        <label for="start-time">Start Time</label>
        <div class="row overflow-x-auto flex-wrap">
          <div v-for="hour in timeSlots" :key="hour" class="col-1 p-2">
            <button type="button" class="w-100"
              :class="['btn', 'btn-outline-primary', { active: hour === reservation.startTime }]"
              :disabled="isReserved(reservation.date, hour) ? true : false" @click="selectStartTime(hour)">
              {{ hour }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="filteredEndTimeSlots.length > 0" class="form-group">
        <label for="end-time">End Time</label>
        <div class="row d-flex overflow-x-auto flex-nowrap ">
          <div v-for="hour in filteredEndTimeSlots" :key="hour" class="col-1 p-2">
            <button type="button" class="w-100"
              :class="['btn', 'btn-outline-primary', { active: hour === reservation.endTime }]"
              :disabled="isEndTimeReserved(reservation.date, hour) ? true : false" @click="selectEndTime(hour)">
              {{ hour }}
            </button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="remark">Remarque</label>
        <textarea v-model="reservation.remark" class="form-control"></textarea>
      </div>
      <button type="submit" class="btn btn-primary mt-3">Reserve</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      reservation: {
        name: '',
        email: '',
        date: '',
        startTime: '',
        endTime: '',
        is_subscription: false,
        remark: ''
      },
      availableDates: [],
      timeSlots: [],
      filteredEndTimeSlots: [],
      terrainCount: 2
    };
  },
  async mounted() {
    if (!this.$route.query.fieldReference) {
      await this.$router.push({ name: 'customers-index' })
    }
    await this.$store.dispatch("reservation/getAll");
    await this.$store.dispatch("subscription/getActiveSubscription", this.$route.query.fieldReference);
    await this.generateAvailableDates();
  },
  methods: {
    generateAvailableDates() {
      const dates = [];
      const today = new Date();
      const locale = 'fr-FR';

      for (let i = 0; i < 60; i++) { // Generate dates for the next 60 days (approximately 2 months)
        const currentDate = new Date();
        currentDate.setDate(today.getDate() + i);

        // Get day name and capitalize the first letter
        let dayName = currentDate.toLocaleDateString(locale, { weekday: 'short' }).replace('.', '');
        dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

        const day = currentDate.toLocaleDateString(locale, { day: 'numeric' });

        // Get month name, shorten it to the first 4 letters, and capitalize the first letter
        let month = currentDate.toLocaleDateString(locale, { month: 'long' });
        month = month.substring(0, 4).charAt(0).toUpperCase() + month.substring(1, 4);

        dates.push({
          value: currentDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
          label: `${dayName} </br> ${day} </br> ${month}`,
        });
      }

      this.availableDates = dates;
    },

    generateTimeSlots() {
      if (!this.reservation.date) return;

      const currentDate = new Date();
      const selectedDate = new Date(this.reservation.date);
      const currentHour = currentDate.getHours();
      const currentMinute = new Date().getMinutes();
      const currentTime = currentHour * 60 + currentMinute; // Convert current time to minutes

      this.timeSlots = [];

      if (selectedDate.toDateString() === currentDate.toDateString()) {
        // Si la date sélectionnée est aujourd'hui
        const timeSlots = [
          // '08:00', '09:10', '10:20', '11:30', '12:40',
          // '13:50', '15:00', '16:10', '17:20', '18:30',
          // '19:40', '20:50', '22:00', '23:10'
          '08:50',
          '10:00',
          '11:10',
          '12:20',
          '13:30',
          '14:40',
          '15:50',

          '17:00',
          '18:10',
          '19:20',
          '20:30',
          '21:40',
          '22:50',
          '23:00',
          '23:59',
        ];

        for (let i = 0; i < timeSlots.length; i++) {
          const [hour, minute] = timeSlots[i].split(':').map(Number);
          const timeInMinutes = hour * 60 + minute;

          if (timeInMinutes > currentTime) {
            this.timeSlots.push(timeSlots[i]);
          }
        }
      }
      else {
        // Sinon, générer des créneaux horaires spécifiques de 08:00 à 23:10
        const slots = [
          // '08:00', '09:10', '10:20', '11:30', '12:40',
          // '13:50', '15:00', '16:10', '17:20', '18:30',
          // '19:40', '20:50', '22:00', '23:10'
          '08:50',
          '10:00',
          '11:10',
          '12:20',
          '13:30',
          '14:40',
          '15:50',

          '17:00',
          '18:10',
          '19:20',
          '20:30',
          '21:40',
          '22:50',
          '23:00',
          '23:59',
        ];

        this.timeSlots = slots;
      }
    },

    selectDate(date) {
      this.reservation.date = date;
      this.generateTimeSlots();
    },
    selectStartTime(time) {
      this.reservation.startTime = time;
      this.updateFilteredEndTimeSlots();
    },
    selectEndTime(time) {
      this.reservation.endTime = time;
    },
    updateFilteredEndTimeSlots() {
      const timeSlots = [
        // '08:00', '09:10', '10:20', '11:30', '12:40',
        // '13:50', '15:00', '16:10', '17:20', '18:30',
        // '19:40', '20:50', '22:00', '23:10', '00:10'
        '08:50',
        '10:00',
        '11:10',
        '12:20',
        '13:30',
        '14:40',
        '15:50',

        '17:00',
        '18:10',
        '19:20',
        '20:30',
        '21:40',
        '22:50',
        '23:00',
        '23:59',
      ];

      const startTime = this.reservation.startTime;
      const startIndex = timeSlots.indexOf(startTime);
      this.filteredEndTimeSlots = [];

      if (startIndex !== -1) {
        // Function to add specified hours and minutes to a time string
        const addTime = (time, hoursToAdd, minutesToAdd) => {
          const [hours, minutes] = time.split(':').map(Number);
          let newHours = hours + hoursToAdd;
          let newMinutes = minutes + minutesToAdd;

          if (newMinutes >= 60) {
            newHours++;
            newMinutes -= 60;
          }

          if (newHours >= 24) {
            newHours -= 24;
          }

          return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
        };

        // Add the next two time slots
        let firstSlot = addTime(startTime, 1, 0);  // Add 1 hour
        this.filteredEndTimeSlots.push(firstSlot);

        let secondSlot = addTime(startTime, 2, 0);  // Add 2 hours and 10 minutes
        this.filteredEndTimeSlots.push(secondSlot);
      }

      // Reset the end time of the reservation
      this.reservation.endTime = '';
    },

    submitReservation() {
      this.reservation.field_reference = this.$route.query.fieldReference
      this.$store.dispatch("reservation/store", this.reservation);
      console.log('Reservation details:', this.reservation);
      // alert('Reservation successful!');
    },

    isReserved(date, hour) {
      if (date && hour) {
        const hourInt = parseInt(hour.split(':')[0], 10);
        const reservationsInDateAndHour = this.reservations.filter(reservation => {
          const reservationDate = reservation.date;
          const reservationStartHour = parseInt(reservation.startTime.split(':')[0], 10);
          const reservationEndHour = parseInt(reservation.endTime.split(':')[0], 10);
          return reservationDate === date && hourInt >= reservationStartHour && hourInt < reservationEndHour;
        });
        return reservationsInDateAndHour.length >= this.terrainCount;
      }
      return null;
    },
    isEndTimeReserved(date, hour) {
      if (date && hour) {
        const hourInt = parseInt(hour.split(':')[0], 10);
        const reservationsInDateAndHour = this.reservations.filter(reservation => {
          const reservationDate = reservation.date;
          const reservationStartHour = parseInt(reservation.startTime.split(':')[0], 10);
          const reservationEndHour = parseInt(reservation.endTime.split(':')[0], 10);
          return reservationDate === date && hourInt >= reservationStartHour && hourInt < reservationEndHour;
        });
        return reservationsInDateAndHour.length > this.terrainCount;
      }
      return null;
    },
  },
  computed: {
    ...mapGetters('reservation', {
      reservations: 'getReservations',
    }),
    ...mapGetters("subscription", {
      subscription: "getSubscription",
    }),
  },
};
</script>

<style scoped></style>
